// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-asientos-extemporaneos-js": () => import("./../src/pages/servicios/asientos_extemporaneos.js" /* webpackChunkName: "component---src-pages-servicios-asientos-extemporaneos-js" */),
  "component---src-pages-servicios-cambio-de-nombre-js": () => import("./../src/pages/servicios/cambio_de_nombre.js" /* webpackChunkName: "component---src-pages-servicios-cambio-de-nombre-js" */),
  "component---src-pages-servicios-compra-venta-de-propiedades-js": () => import("./../src/pages/servicios/compra_venta_de_propiedades.js" /* webpackChunkName: "component---src-pages-servicios-compra-venta-de-propiedades-js" */),
  "component---src-pages-servicios-contratos-de-arrendamiento-js": () => import("./../src/pages/servicios/contratos_de_arrendamiento.js" /* webpackChunkName: "component---src-pages-servicios-contratos-de-arrendamiento-js" */),
  "component---src-pages-servicios-defensa-tecnica-js": () => import("./../src/pages/servicios/defensa_tecnica.js" /* webpackChunkName: "component---src-pages-servicios-defensa-tecnica-js" */),
  "component---src-pages-servicios-divorcios-js": () => import("./../src/pages/servicios/divorcios.js" /* webpackChunkName: "component---src-pages-servicios-divorcios-js" */),
  "component---src-pages-servicios-doble-nacionalidad-js": () => import("./../src/pages/servicios/doble_nacionalidad.js" /* webpackChunkName: "component---src-pages-servicios-doble-nacionalidad-js" */),
  "component---src-pages-servicios-guarda-y-custodia-js": () => import("./../src/pages/servicios/guarda_y_custodia.js" /* webpackChunkName: "component---src-pages-servicios-guarda-y-custodia-js" */),
  "component---src-pages-servicios-identificacion-de-persona-js": () => import("./../src/pages/servicios/identificacion_de_persona.js" /* webpackChunkName: "component---src-pages-servicios-identificacion-de-persona-js" */),
  "component---src-pages-servicios-inmovilizacion-de-bienes-js": () => import("./../src/pages/servicios/inmovilizacion_de_bienes.js" /* webpackChunkName: "component---src-pages-servicios-inmovilizacion-de-bienes-js" */),
  "component---src-pages-servicios-js": () => import("./../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-servicios-mandatos-js": () => import("./../src/pages/servicios/mandatos.js" /* webpackChunkName: "component---src-pages-servicios-mandatos-js" */),
  "component---src-pages-servicios-matrimonios-js": () => import("./../src/pages/servicios/matrimonios.js" /* webpackChunkName: "component---src-pages-servicios-matrimonios-js" */),
  "component---src-pages-servicios-reconocimiento-de-hijos-js": () => import("./../src/pages/servicios/reconocimiento_de_hijos.js" /* webpackChunkName: "component---src-pages-servicios-reconocimiento-de-hijos-js" */),
  "component---src-pages-servicios-rectificacion-y-reposicion-de-partidas-js": () => import("./../src/pages/servicios/rectificacion_y_reposicion_de_partidas.js" /* webpackChunkName: "component---src-pages-servicios-rectificacion-y-reposicion-de-partidas-js" */),
  "component---src-pages-servicios-restitucion-de-bienes-js": () => import("./../src/pages/servicios/restitucion_de_bienes.js" /* webpackChunkName: "component---src-pages-servicios-restitucion-de-bienes-js" */),
  "component---src-pages-servicios-testamentarios-o-intestados-js": () => import("./../src/pages/servicios/testamentarios_o_intestados.js" /* webpackChunkName: "component---src-pages-servicios-testamentarios-o-intestados-js" */),
  "component---src-pages-servicios-testamentos-js": () => import("./../src/pages/servicios/testamentos.js" /* webpackChunkName: "component---src-pages-servicios-testamentos-js" */),
  "component---src-pages-servicios-titulacion-supletoria-js": () => import("./../src/pages/servicios/titulacion_supletoria.js" /* webpackChunkName: "component---src-pages-servicios-titulacion-supletoria-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-consulate-js": () => import("./../src/templates/consulate.js" /* webpackChunkName: "component---src-templates-consulate-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

